function mameCompatible(c) {
    return vowel(c) || checkCharPresence(c, CONS_GROUP1);
}
function babeCompatible(c) {
    return checkCharPresence(c, CONS_GROUP2);
}
function chooseMBP(c, softOffset, mAffixes, bAffixes, pAffixes) {
    if (mameCompatible(c)) {
        return mAffixes[softOffset];
    }
    if (babeCompatible(c)) {
        return bAffixes[softOffset];
    }
    return pAffixes[softOffset];
}
function getIntentionFutureAffix(c, softOffset) {
    return chooseMBP(c, softOffset, MAKMEK, BAKBEK, PAKPEK);
}
function getImperativeVowel(person, number, c, softOffset) {
    if (person == GrammarPerson.First) {
        if (genuineVowel(c)) {
            return "";
        }
        return AE[softOffset];
    }
    if (person == GrammarPerson.Second) {
        if (number == GrammarNumber.Singular || genuineVowel(c)) {
            return "";
        }
        return YI[softOffset];
    }
    if (person == GrammarPerson.SecondPolite) {
        if (genuineVowel(c)) {
            return "";
        }
        return YI[softOffset];
    }
    return "";
}
function getImperativeAffix(person, number, c, softOffset) {
    let vowel = getImperativeVowel(person, number, c, softOffset);
    let affix = IMPERATIVE_AFFIXES[person][number][softOffset];
    return `${vowel}${affix}`;
}
function extractLastNounPart(nounDictForm) {
    let sep = nounDictForm.length - 1;
    while (sep >= 0) {
        const ch = nounDictForm[sep];
        if (ch == ' ' || ch == '-') {
            break;
        }
        --sep;
    }
    if (sep < 0) {
        return nounDictForm;
    }
    return nounDictForm.substring(sep + 1);
}
class DeclensionAltInfo {
    constructor(noun, dropVowelMeaning, keepVowelMeaning) {
        this.noun = noun;
        this.dropVowelMeaning = dropVowelMeaning;
        this.keepVowelMeaning = keepVowelMeaning;
    }
}
function getDeclAltInfo(nounDictForm) {
    const lastPart = extractLastNounPart(nounDictForm).toLowerCase();
    if (OPTIONALLY_DROP_LAST_VOWEL_NOUNS.has(lastPart)) {
        const meanings = OPTIONALLY_DROP_LAST_VOWEL_NOUNS.get(lastPart);
        if (meanings.length == 2) {
            return new DeclensionAltInfo(lastPart, meanings[1], meanings[0]);
        }
    }
    return null;
}
function replaceBaseLastForPossessive(baseBuilder, lastBase) {
    const replacement = BASE_REPLACEMENT_PKKH.get(lastBase);
    if (replacement != null) {
        return baseBuilder.replaceLast(replacement);
    }
    else {
        return baseBuilder;
    }
}
function dropLastVowelImpl(base) {
    const n = base.length;
    return base.substring(0, n - 2) + base[n - 1];
}
function dropLastVowel(baseBuilder) {
    const lastPart = baseBuilder.getLastPart();
    const modified = dropLastVowelImpl(lastPart.content);
    const modifiedPart = lastPart.copy(modified);
    return baseBuilder.replaceLastPart(modifiedPart);
}
class ModifiedBase {
    constructor(base, endsWithVowel) {
        this.base = base;
        this.endsWithVowel = endsWithVowel;
    }
}
class NounBuilder {
    constructor(...arr) {
        if (arr.length == 1) {
            if (typeof arr[0] === 'string') {
                const nounDictForm = arr[0];
                this.baseBuilder = new PhrasalBuilder().nounBase(nounDictForm);
                this.soft = wordIsSoft(nounDictForm);
                this.softOffset = this.soft ? SOFT_OFFSET : HARD_OFFSET;
            }
            else {
                throw new Error("Invalid single arguments");
            }
        }
        else if (arr.length == 2) {
            if (arr[0] instanceof PhrasalBuilder && typeof arr[1] === 'boolean') {
                this.baseBuilder = arr[0];
                this.soft = arr[1];
                this.softOffset = this.soft ? SOFT_OFFSET : HARD_OFFSET;
            }
            else {
                throw new Error("Invalid pair of arguments");
            }
        }
        else {
            throw new Error("Invalid number of arguments");
        }
    }
    copyBase() {
        return this.baseBuilder.copy();
    }
    getPluralAffix(baseLast) {
        if (vowel(baseLast) || checkCharPresence(baseLast, CONS_GROUP7)) {
            return LARLER[this.softOffset];
        }
        if (checkCharPresence(baseLast, CONS_GROUP8)) {
            return DARDER[this.softOffset];
        }
        return TARTER[this.softOffset];
    }
    pluralBuilder() {
        let lastBase = this.baseBuilder.getLastItem();
        let pluralAffix = this.getPluralAffix(lastBase);
        return this.copyBase()
            .pluralAffix(pluralAffix);
    }
    pluralize() {
        return this.pluralBuilder()
            .build();
    }
    getDropVowelType() {
        const rawBase = this.baseBuilder.getFirstPart().content;
        const lastPart = extractLastNounPart(rawBase).toLowerCase();
        if (DROP_LAST_VOWEL_NOUNS.has(lastPart)) {
            return DropVowelType.DropLast;
        }
        else if (OPTIONALLY_DROP_LAST_VOWEL_NOUNS.has(lastPart)) {
            return DropVowelType.OptionallyDropLast;
        }
        else {
            return DropVowelType.Regular;
        }
    }
    modifyBaseForSomePossessive() {
        const dropVowelType = this.getDropVowelType();
        let lastBase = this.baseBuilder.getLastItem();
        if (dropVowelType == DropVowelType.Regular) {
            let builderWithReplacement = replaceBaseLastForPossessive(this.copyBase(), lastBase);
            const modifiedBase = new ModifiedBase(builderWithReplacement, genuineVowel(builderWithReplacement.getLastItem()));
            return [modifiedBase];
        }
        else if (dropVowelType == DropVowelType.DropLast) {
            let builderWithDrop = dropLastVowel(this.copyBase());
            const modifiedBase = new ModifiedBase(builderWithDrop, false);
            return [modifiedBase];
        }
        else {
            let builderWithDrop = dropLastVowel(this.copyBase());
            const modifiedWithDrop = new ModifiedBase(builderWithDrop, false);
            const builderWithReplacement = replaceBaseLastForPossessive(this.copyBase(), lastBase);
            const modifiedWithReplacement = new ModifiedBase(builderWithReplacement, genuineVowel(builderWithReplacement.getLastItem()));
            return [modifiedWithDrop, modifiedWithReplacement];
        }
    }
    singlePossessiveBuilder(base, person, number) {
        let extra = "";
        if (person == GrammarPerson.Third) {
            if (base.endsWithVowel) {
                extra = "с";
            }
        }
        else {
            if (!base.endsWithVowel) {
                extra = YI[this.softOffset];
            }
        }
        const affix = NOUN_POSSESSIVE_AFFIXES[person][number][this.softOffset];
        return base.base.copy()
            .possessiveAffix(`${extra}${affix}`);
    }
    buildPossessiveWithAlternative(bases, person, number) {
        let mainBuilder = this.singlePossessiveBuilder(bases[0], person, number);
        if (bases.length > 1) {
            const alternative = this.singlePossessiveBuilder(bases[1], person, number);
            mainBuilder = mainBuilder.addAlternative(alternative);
        }
        return mainBuilder;
    }
    possessiveBuilder(person, number) {
        if (person == GrammarPerson.First) {
            const bases = this.modifyBaseForSomePossessive();
            return this.buildPossessiveWithAlternative(bases, person, number);
        }
        else if (person == GrammarPerson.Second || person == GrammarPerson.SecondPolite) {
            if (number == GrammarNumber.Singular) {
                const bases = this.modifyBaseForSomePossessive();
                return this.buildPossessiveWithAlternative(bases, person, number);
            }
            else {
                const baseWithNumber = this.pluralBuilder();
                const extraVowel = YI[this.softOffset];
                const affix = NOUN_POSSESSIVE_AFFIXES[person][number][this.softOffset];
                return baseWithNumber
                    .possessiveAffix(`${extraVowel}${affix}`);
            }
        }
        else if (person == GrammarPerson.Third) {
            if (number == GrammarNumber.Singular) {
                const bases = this.modifyBaseForSomePossessive();
                return this.buildPossessiveWithAlternative(bases, person, number);
            }
            else {
                const baseWithNumber = this.pluralBuilder();
                const affix = NOUN_POSSESSIVE_AFFIXES[person][number][this.softOffset];
                return baseWithNumber
                    .possessiveAffix(affix);
            }
        }
        return new PhrasalBuilder();
    }
    possessive(person, number) {
        let builder = this.possessiveBuilder(person, number);
        if (builder.isEmpty()) {
            return NOT_SUPPORTED_PHRASAL;
        }
        return builder.build();
    }
    pluralPossessiveBuilder(person, number) {
        if (person != GrammarPerson.First) {
            return this.possessiveBuilder(person, GrammarNumber.Plural);
        }
        let builder = this.pluralBuilder();
        const extraVowel = YI[this.softOffset];
        const affix = NOUN_POSSESSIVE_AFFIXES[person][number][this.softOffset];
        return builder
            .possessiveAffix(`${extraVowel}${affix}`);
    }
    pluralPossessive(person, number) {
        let builder = this.pluralPossessiveBuilder(person, number);
        if (builder.isEmpty()) {
            return NOT_SUPPORTED_PHRASAL;
        }
        return builder.build();
    }
    getShygysAffix(last, thirdPersonPoss) {
        if (thirdPersonPoss || checkCharPresence(last, CONS_GROUP6)) {
            return NANNEN[this.softOffset];
        }
        else if (vowel(last) || checkCharPresence(last, CONS_GROUP1_3)) {
            return DANDEN[this.softOffset];
        }
        else {
            return TANTEN[this.softOffset];
        }
    }
    getJatysAffix(last, thirdPersonPoss) {
        if (thirdPersonPoss) {
            return NDANDE[this.softOffset];
        }
        else if (vowel(last) || checkCharPresence(last, CONS_GROUP1_2)) {
            return DADE[this.softOffset];
        }
        else {
            return TATE[this.softOffset];
        }
    }
    getRelatedAdjAffix(last, thirdPersonPoss) {
        if (thirdPersonPoss) {
            return NDAGYNDEGI[this.softOffset];
        }
        else if (vowel(last) || checkCharPresence(last, CONS_GROUP1_2)) {
            return DAGYDEGI[this.softOffset];
        }
        else {
            return TAGYTEGI[this.softOffset];
        }
    }
    getBarysAffix(last, person, number) {
        if ((person == GrammarPerson.First && number == GrammarNumber.Singular) || person == GrammarPerson.Second) {
            return AE[this.softOffset];
        }
        else if (person == GrammarPerson.Third) {
            return NANE[this.softOffset];
        }
        else {
            if (vowel(last) || checkCharPresence(last, CONS_GROUP1_2)) {
                return GAGE[this.softOffset];
            }
            else {
                return KAKE[this.softOffset];
            }
        }
    }
    getIlikAffix(last, thirdPersonPoss) {
        if (checkCharPresence(last, VOWELS_GROUP1) || checkCharPresence(last, CONS_GROUP1_3)) {
            return DYNGDING[this.softOffset];
        }
        else if (vowel(last) || checkCharPresence(last, CONS_GROUP6) || thirdPersonPoss) {
            return NYNGNING[this.softOffset];
        }
        else {
            return TYNGTING[this.softOffset];
        }
    }
    getTabysAffix(last, thirdPersonPoss) {
        if (thirdPersonPoss) {
            return "н";
        }
        else if (checkCharPresence(last, VOWELS_GROUP1) || checkCharPresence(last, CONS_GROUP1_2)) {
            return DYDI[this.softOffset];
        }
        else if (vowel(last)) {
            return NYNI[this.softOffset];
        }
        else {
            return TYTI[this.softOffset];
        }
    }
    getKomektesAffix(last, thirdPersonPoss) {
        if (thirdPersonPoss || vowel(last) || checkCharPresence(last, CONS_GROUP1_6)) {
            return "мен";
        }
        else if (checkCharPresence(last, CONS_GROUP3)) {
            return "бен";
        }
        else {
            return "пен";
        }
    }
    septikForm(septik) {
        if (septik == Septik.Atau) {
            return this.copyBase()
                .build();
        }
        const lastBase = this.baseBuilder.getLastItem();
        if (septik == Septik.Shygys) {
            let affix = this.getShygysAffix(lastBase, false);
            return this.copyBase()
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Jatys) {
            let affix = this.getJatysAffix(lastBase, false);
            return this.copyBase()
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Barys) {
            let affix = this.getBarysAffix(lastBase, null, null);
            return this.copyBase()
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Ilik) {
            let affix = this.getIlikAffix(lastBase, false);
            return this.copyBase()
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Tabys) {
            let affix = this.getTabysAffix(lastBase, false);
            return this.copyBase()
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Komektes) {
            let affix = this.getKomektesAffix(lastBase, false);
            return this.copyBase()
                .septikAffix(affix)
                .build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    pluralSeptikForm(septik) {
        let builder = this.pluralBuilder();
        if (septik == Septik.Atau) {
            return builder
                .build();
        }
        else if (septik == Septik.Shygys) {
            const affix = DANDEN[this.softOffset];
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Jatys) {
            const affix = DADE[this.softOffset];
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Barys) {
            const affix = GAGE[this.softOffset];
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Ilik) {
            const affix = DYNGDING[this.softOffset];
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Tabys) {
            const affix = DYDI[this.softOffset];
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Komektes) {
            const affix = "мен";
            return builder
                .septikAffix(affix)
                .build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    possessiveSeptikForm(person, number, septik) {
        let builder = this.possessiveBuilder(person, number);
        if (builder.isEmpty()) {
            return NOT_SUPPORTED_PHRASAL;
        }
        if (septik == Septik.Atau) {
            return builder
                .build();
        }
        const lastBase = builder.getLastItem();
        if (septik == Septik.Shygys) {
            const affix = this.getShygysAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Jatys) {
            const affix = this.getJatysAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Barys) {
            const affix = this.getBarysAffix(lastBase, person, number);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Ilik) {
            const affix = this.getIlikAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Tabys) {
            const affix = this.getTabysAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Komektes) {
            const affix = this.getKomektesAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    pluralPossessiveSeptikForm(person, number, septik) {
        let builder = this.pluralPossessiveBuilder(person, number);
        if (builder.isEmpty()) {
            return NOT_SUPPORTED_PHRASAL;
        }
        if (septik == Septik.Atau) {
            return builder
                .build();
        }
        const lastBase = builder.getLastItem();
        if (septik == Septik.Shygys) {
            const affix = this.getShygysAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Jatys) {
            const affix = this.getJatysAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Barys) {
            const affix = this.getBarysAffix(lastBase, person, number);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Ilik) {
            const affix = this.getIlikAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Tabys) {
            const affix = this.getTabysAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        else if (septik == Septik.Komektes) {
            const affix = this.getKomektesAffix(lastBase, person == GrammarPerson.Third);
            return builder
                .septikAffix(affix)
                .build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    getSpecialPossessiveAffix(last) {
        if (genuineVowel(last)) {
            return "нікі";
        }
        else if (checkCharPresence(last, CONS_GROUP1_2)) {
            return "дікі";
        }
        else {
            return "тікі";
        }
    }
    specialPossessive() {
        const lastBase = this.baseBuilder.getLastItem();
        let affix = this.getSpecialPossessiveAffix(lastBase);
        return this.copyBase()
            .possessiveAffix(affix)
            .build();
    }
    pluralSpecialPossessive() {
        let builder = this.pluralBuilder();
        const affix = "дікі";
        return builder
            .possessiveAffix(affix)
            .build();
    }
    relatedAdj() {
        const lastBase = this.baseBuilder.getLastItem();
        let affix = this.getRelatedAdjAffix(lastBase, false);
        return this.copyBase()
            .septikAffix(affix)
            .build();
    }
    possessiveRelatedAdj(person, number) {
        let builder = this.possessiveBuilder(person, number);
        if (builder.isEmpty()) {
            return NOT_SUPPORTED_PHRASAL;
        }
        const lastBase = builder.getLastItem();
        const affix = this.getRelatedAdjAffix(lastBase, person == GrammarPerson.Third);
        return builder
            .septikAffix(affix)
            .build();
    }
}
const LARLER = ["лар", "лер"];
const TARTER = ["тар", "тер"];
const DARDER = ["дар", "дер"];
const DANDEN = ["дан", "ден"];
const TANTEN = ["тан", "тен"];
const NANNEN = ["нан", "нен"];
const DADE = ["да", "де"];
const TATE = ["та", "те"];
const NDANDE = ["нда", "нде"];
const DAGYDEGI = ["дағы", "дегі"];
const TAGYTEGI = ["тағы", "тегі"];
const NDAGYNDEGI = ["ндағы", "ндегі"];
const GAGE = ["ға", "ге"];
const KAKE = ["қа", "ке"];
const NANE = ["на", "не"];
const DYNGDING = ["дың", "дің"];
const TYNGTING = ["тың", "тің"];
const NYNGNING = ["ның", "нің"];
const NYNI = ["ны", "ні"];
const NOUN_POSSESSIVE_AFFIXES = {
    First: {
        Singular: ["м", "м"],
        Plural: ["мыз", "міз"],
    },
    Second: {
        Singular: ["ң", "ң"],
        Plural: ["ң", "ң"],
    },
    SecondPolite: {
        Singular: ["ңыз", "ңіз"],
        Plural: ["ңыз", "ңіз"],
    },
    Third: {
        Singular: ["ы", "і"],
        Plural: ["ы", "і"],
    }
};
const BASE_REPLACEMENT_PKKH = new Map([
    ["п", "б"],
    ["к", "г"],
    ["қ", "ғ"],
]);
var DropVowelType;
(function (DropVowelType) {
    DropVowelType[DropVowelType["Regular"] = 0] = "Regular";
    DropVowelType[DropVowelType["DropLast"] = 1] = "DropLast";
    DropVowelType[DropVowelType["OptionallyDropLast"] = 2] = "OptionallyDropLast";
})(DropVowelType || (DropVowelType = {}));
;
var Septik;
(function (Septik) {
    Septik[Septik["Atau"] = 0] = "Atau";
    Septik[Septik["Ilik"] = 1] = "Ilik";
    Septik[Septik["Barys"] = 2] = "Barys";
    Septik[Septik["Tabys"] = 3] = "Tabys";
    Septik[Septik["Jatys"] = 4] = "Jatys";
    Septik[Septik["Shygys"] = 5] = "Shygys";
    Septik[Septik["Komektes"] = 6] = "Komektes";
})(Septik || (Septik = {}));
;
const SEPTIKS = [Septik.Atau, Septik.Ilik, Septik.Barys, Septik.Tabys, Septik.Jatys, Septik.Shygys, Septik.Komektes];
const DROP_LAST_VOWEL_NOUNS = new Set([
    "әріп",
    "бөрік",
    "ғұрып",
    "дәріп",
    "ерік",
    "ерін",
    "зауық",
    "кейіп",
    "қаріп",
    "қауіп",
    "құлық",
    "құлып",
    "мойын",
    "мүлік",
    "мұрын",
    "орын",
    "парық",
    "сиық",
    "сұрық",
    "халық",
    "шырық",
    "ырық",
]);
const OPTIONALLY_DROP_LAST_VOWEL_NOUNS = new Map([
    ["ауыз", ["рот", "рот"]],
    ["дауыс", ["звук", "звук"]],
    ["көрік", ["красота", "кузнечный мех"]],
    ["қалып", ["состояние", "колодка, шаблон"]],
    ["қарын", ["желудок", "желудок"]],
    ["қойын", ["пазуха", "пазуха"]],
    ["нарық", ["расценка, тариф", "расценка, тариф"]],
    ["тұрық", ["длина", "местожительство"]],
]);
function getFirstPersAffix1LetterGroup(c) {
    if (checkCharPresence(c, CONS_GROUP3)) {
        return PersAffix1LetterGroup.PersAffix1GzGroup;
    }
    if (checkCharPresence(c, CONS_GROUP4) || checkCharPresence(c, CONS_GROUP5)) {
        return PersAffix1LetterGroup.PersAffixUnvoicedGroup;
    }
    if (checkCharPresence(c, CONS_GROUP6)) {
        return PersAffix1LetterGroup.PersAffix1MnGroup;
    }
    return PersAffix1LetterGroup.PersAffix1DefaultGroup;
}
function getFirstPersAffix1(number, c, softOffset) {
    const group = getFirstPersAffix1LetterGroup(c);
    return FIRST_PERS_AFFIXES1[number][group][softOffset];
}
function getPersAffix1(person, number, c, softOffset) {
    if (person == GrammarPerson.First) {
        return getFirstPersAffix1(number, c, softOffset);
    }
    if (person == GrammarPerson.Second) {
        return SECOND_PERS_AFFIXES1[number][softOffset];
    }
    if (person == GrammarPerson.SecondPolite) {
        return SECOND_POLITE_PERS_AFFIXES1[number][softOffset];
    }
    return "";
}
/* Goes after "п". */
function getPersAffix3(person, number, softOffset) {
    if (person == GrammarPerson.First) {
        return FIRST_PERS_AFFIXES1[number][PersAffix1LetterGroup.PersAffixUnvoicedGroup][softOffset];
    }
    if (person == GrammarPerson.Second) {
        return SECOND_PERS_AFFIXES1[number][softOffset];
    }
    if (person == GrammarPerson.SecondPolite) {
        return SECOND_POLITE_PERS_AFFIXES1[number][softOffset];
    }
    return THIRD_PERS_AFFIXES3[number][softOffset];
}
function vowel(c) {
    return checkCharPresence(c, VOWELS);
}
function genuineVowel(c) {
    return checkCharPresence(c, VOWELS_EXCEPT_U_I);
}
var SoftHardType;
(function (SoftHardType) {
    SoftHardType[SoftHardType["SOFT_STRONG"] = 0] = "SOFT_STRONG";
    SoftHardType[SoftHardType["SOFT_WEAK"] = 1] = "SOFT_WEAK";
    SoftHardType[SoftHardType["NEUTRAL"] = 2] = "NEUTRAL";
    SoftHardType[SoftHardType["HARD_WEAK"] = 3] = "HARD_WEAK";
    SoftHardType[SoftHardType["HARD_STRONG"] = 4] = "HARD_STRONG";
})(SoftHardType || (SoftHardType = {}));
;
const VOWELS_BY_SOFT_HARD = new Map([
    ["ә", SoftHardType.SOFT_STRONG],
    ["е", SoftHardType.SOFT_STRONG],
    ["ө", SoftHardType.SOFT_STRONG],
    ["ү", SoftHardType.SOFT_STRONG],
    ["і", SoftHardType.SOFT_STRONG],
    ["и", SoftHardType.SOFT_WEAK],
    ["ю", SoftHardType.NEUTRAL],
    ["у", SoftHardType.NEUTRAL],
    ["а", SoftHardType.HARD_STRONG],
    ["о", SoftHardType.HARD_STRONG],
    ["ұ", SoftHardType.HARD_STRONG],
    ["ы", SoftHardType.HARD_STRONG],
    ["я", SoftHardType.HARD_STRONG],
]);
function wordIsSoft(raw) {
    const w = raw.toLowerCase();
    if (HARD_SOFT_EXCEPTIONS.has(w)) {
        return HARD_SOFT_EXCEPTIONS.get(w);
    }
    for (let i = w.length - 1; i >= 0; --i) {
        let c = w[i];
        const vtype = VOWELS_BY_SOFT_HARD.get(c);
        if (vtype == undefined) {
            continue;
        }
        if (vtype == SoftHardType.SOFT_STRONG) {
            return true;
        }
        if (vtype == SoftHardType.SOFT_WEAK) {
            return true;
        }
        if (vtype == SoftHardType.HARD_STRONG) {
            return false;
        }
    }
    return false;
}
function fixBgBigrams(w) {
    return (w
        .replace("бп", "пп")
        .replace("гп", "кп")
        .replace("ғп", "қп"));
}
function fixXkBigrams(w) {
    return (w
        .replace("бқ", "пқ")
        .replace("бк", "пк")
        .replace("гк", "кк")
        .replace("ғқ", "ққ"));
}
function fixShortIBigrams(w) {
    return (w
        .replace("йа", "я")
        .replace("ій", "и")
        .replace("ый", "и"));
}
function fixGgbInPastBase(c) {
    if (c == 'г') {
        return 'к';
    }
    if (c == 'ғ') {
        return 'қ';
    }
    if (c == 'б') {
        return 'п';
    }
    return c;
}
var PHRASAL_PART_TYPE;
(function (PHRASAL_PART_TYPE) {
    PHRASAL_PART_TYPE["Unclassified"] = "Unclassified";
    PHRASAL_PART_TYPE["Space"] = "Space";
    PHRASAL_PART_TYPE["Punctuation"] = "Punctuation";
    PHRASAL_PART_TYPE["VerbBase"] = "VerbBase";
    PHRASAL_PART_TYPE["VerbTenseAffix"] = "VerbTenseAffix";
    PHRASAL_PART_TYPE["VerbPersonalAffix"] = "VerbPersonalAffix";
    PHRASAL_PART_TYPE["VerbNegation"] = "VerbNegation";
    PHRASAL_PART_TYPE["QuestionParticle"] = "QuestionParticle";
    PHRASAL_PART_TYPE["NounBase"] = "NounBase";
    PHRASAL_PART_TYPE["PluralAffix"] = "PluralAffix";
    PHRASAL_PART_TYPE["PossessiveAffix"] = "PossessiveAffix";
    PHRASAL_PART_TYPE["SeptikAffix"] = "SeptikAffix";
})(PHRASAL_PART_TYPE || (PHRASAL_PART_TYPE = {}));
var PART_EXPLANATION_TYPE;
(function (PART_EXPLANATION_TYPE) {
    // VerbBase
    PART_EXPLANATION_TYPE["VerbBaseStripU"] = "VerbBaseStripU";
    PART_EXPLANATION_TYPE["VerbBaseLostIShort"] = "VerbBaseLostIShort";
    PART_EXPLANATION_TYPE["VerbBaseLostY"] = "VerbBaseLostY";
    PART_EXPLANATION_TYPE["VerbBaseGainedY"] = "VerbBaseGainedY";
    PART_EXPLANATION_TYPE["VerbBaseGainedIShort"] = "VerbBaseGainedIShort";
    PART_EXPLANATION_TYPE["VerbBaseGainIShortLoseY"] = "VerbBaseGainIShortLoseY";
    PART_EXPLANATION_TYPE["VerbBaseGainedIShortY"] = "VerbBaseGainedIShortY";
    PART_EXPLANATION_TYPE["VerbBaseGainedYInsidePriorCons"] = "VerbBaseGainedYInsidePriorCons";
    PART_EXPLANATION_TYPE["VerbBaseReplaceB2U"] = "VerbBaseReplaceB2U";
    PART_EXPLANATION_TYPE["VerbBaseReplaceLastCons"] = "VerbBaseReplaceLastCons";
    // .. here go other base modifications
    // Negation particles
    PART_EXPLANATION_TYPE["VerbNegationPostBase"] = "VerbNegationPostBase";
    // VerbTenseAffix
    PART_EXPLANATION_TYPE["VerbTenseAffixPresentTransitive"] = "VerbTenseAffixPresentTransitive";
    PART_EXPLANATION_TYPE["VerbTenseAffixPresentTransitiveToYa"] = "VerbTenseAffixPresentTransitiveToYa";
    PART_EXPLANATION_TYPE["VerbTenseAffixPresentTransitiveToYi"] = "VerbTenseAffixPresentTransitiveToYi";
    // VerbPersonalAffix
    PART_EXPLANATION_TYPE["VerbPersonalAffixPresentTransitive"] = "VerbPersonalAffixPresentTransitive";
    PART_EXPLANATION_TYPE["VerbPersonalAffixPresentTransitiveQuestionSkip"] = "VerbPersonalAffixPresentTransitiveQuestionSkip";
    // QuestionParticle
    PART_EXPLANATION_TYPE["QuestionParticleSeparate"] = "QuestionParticleSeparate";
})(PART_EXPLANATION_TYPE || (PART_EXPLANATION_TYPE = {}));
class PartExplanation {
    constructor(explanationType, soft, softPos = -1, variant = -1) {
        this.explanationType = explanationType;
        this.soft = soft;
        this.softPos = softPos;
        this.variant = variant;
    }
}
class PhrasalPart {
    constructor(partType, content, aux = false, explanation = null) {
        this.partType = partType;
        this.content = content;
        this.aux = aux;
        this.explanation = explanation;
    }
    copy(content) {
        return new PhrasalPart(this.partType, content, this.aux, this.explanation);
    }
}
class Phrasal {
    constructor(parts, raw, forbidden, alternative) {
        this.parts = parts;
        this.raw = raw;
        this.forbidden = forbidden;
        this.alternative = alternative;
    }
}
const NOT_SUPPORTED = "<not supported>";
const NOT_SUPPORTED_PHRASAL = new Phrasal([], NOT_SUPPORTED, false, null);
class PhrasalBuilder {
    constructor() {
        this.parts = [];
        this.alternative = null;
    }
    copy() {
        let copy = new PhrasalBuilder();
        for (let i = 0; i < this.parts.length; ++i) {
            copy.parts.push(this.parts[i]);
        }
        copy.forbidden = this.forbidden;
        copy.alternative = this.alternative;
        return copy;
    }
    isEmpty() {
        return this.parts.length === 0;
    }
    addPart(part, allowEmpty = false) {
        if (part.content.length > 0 || allowEmpty) {
            this.parts.push(part);
        }
        return this;
    }
    unclassified(s) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.Unclassified, s));
    }
    space() {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.Space, " "));
    }
    punctuation(mark) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.Punctuation, mark));
    }
    verbBase(verbBase) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbBase, verbBase));
    }
    verbBaseWithExplanation(verbBase, explanation) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbBase, verbBase, false, explanation));
    }
    tenseAffix(affix) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbTenseAffix, affix));
    }
    tenseAffixWithExplanation(affix, explanation) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbTenseAffix, affix, false, explanation));
    }
    personalAffix(affix) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbPersonalAffix, affix));
    }
    personalAffixWithExplanation(affix, explanation) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbPersonalAffix, affix, false, explanation), 
        /* allowEmpty */ true);
    }
    negation(particle) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbNegation, particle));
    }
    negationWithExplanation(particle, explanation) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.VerbNegation, particle, false, explanation));
    }
    questionParticle(particle) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.QuestionParticle, particle));
    }
    questionParticleWithExplanation(particle, explanation) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.QuestionParticle, particle, false, explanation));
    }
    auxVerb(phrasal) {
        for (let i = 0; i < phrasal.parts.length; ++i) {
            let part = phrasal.parts[i];
            this.addPart(new PhrasalPart(part.partType, part.content, /* aux */ true));
        }
        return this;
    }
    nounBase(nounBase) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.NounBase, nounBase));
    }
    pluralAffix(affix) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.PluralAffix, affix));
    }
    possessiveAffix(affix) {
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.PossessiveAffix, affix));
    }
    septikAffix(affix) {
        if (this.alternative != null) {
            this.alternative.septikAffix(affix);
        }
        return this.addPart(new PhrasalPart(PHRASAL_PART_TYPE.SeptikAffix, affix));
    }
    setForbidden(forbidden) {
        this.forbidden = forbidden;
        return this;
    }
    markForbidden() {
        return this.setForbidden(true);
    }
    addAlternative(alternative) {
        this.alternative = alternative;
        return this;
    }
    getFirstPart() {
        return this.parts[0];
    }
    getLastNonemptyIndex() {
        let parts = this.parts;
        let index = parts.length - 1;
        while (index > 0 && parts[index].content.length === 0) {
            index--;
        }
        return index;
    }
    getLastItem() {
        const index = this.getLastNonemptyIndex();
        return getLastItemLowered(this.parts[index].content);
    }
    getLastPart() {
        const index = this.getLastNonemptyIndex();
        return this.parts[index];
    }
    replaceLastPart(part) {
        let index = this.getLastNonemptyIndex();
        this.parts[index] = part;
        return this;
    }
    replaceLast(replacement) {
        let index = this.getLastNonemptyIndex();
        let lastPart = this.getLastPart();
        const replaced = replaceLast(lastPart.content, replacement);
        return this.replaceLastPart(lastPart.copy(replaced));
    }
    build() {
        let partStrings = [];
        for (let i = 0; i < this.parts.length; ++i) {
            partStrings.push(`${this.parts[i].content}`);
        }
        const builtAlternative = (this.alternative != null
            ? this.alternative.build()
            : null);
        return new Phrasal(this.parts, partStrings.join(""), this.forbidden, builtAlternative);
    }
}
function finalizeMaybePhrasalBuilder(builder) {
    if (builder === null) {
        return NOT_SUPPORTED_PHRASAL;
    }
    return builder.build();
}
function getQuestionParticle(c, softOffset) {
    return chooseMBP(c, softOffset, MAME, BABE, PAPE);
}
function getColloquialQuestionParticle(c, softOffset) {
    return chooseMBP(c, softOffset, CLQ_MAME, CLQ_BABE, CLQ_PAPE);
}
const VOWELS = "аәеиоөуүұыіэюя";
const VOWELS_EXCEPT_U_I = "аәеоөүұыіэюя";
const VOWELS_GROUP1 = "ию";
const SOFT_VOWELS = "әеөүі";
const HARD_VOWELS = "аоұы";
const CONS_GROUP1 = "руйл";
const CONS_GROUP2 = "жзмнң";
const CONS_GROUP3 = "жз";
const CONS_GROUP4 = "кқпстфхцчшщ"; // қатаң дыбыстар from https://qazaq-til-asar.livejournal.com/2594.html
const CONS_GROUP5 = "бвгғд";
const CONS_GROUP6 = "мнң";
const CONS_GROUP7 = "руй";
const CONS_GROUP8 = "жзлмнң";
const CONS_GROUP1_2 = CONS_GROUP1 + CONS_GROUP2;
const CONS_GROUP1_3 = CONS_GROUP1 + CONS_GROUP3;
const CONS_GROUP1_6 = CONS_GROUP1 + CONS_GROUP6;
const HARD_SOFT_EXCEPTIONS = new Map([
    // false - hard, true - soft
    // verbs
    ["ағжию", false],
    ["ақсию", false],
    ["ақшию", false],
    ["аңқию", false],
    ["апсию", false],
    ["арбию", false],
    ["арсию", false],
    ["аусию", false],
    ["бағбию", false],
    ["бағжию", false],
    ["бажбию", false],
    ["бақшию", false],
    ["балпию", false],
    ["балшию", false],
    ["барбию", false],
    ["баржию", false],
    ["бартию", false],
    ["баттию", false],
    ["божбию", false],
    ["болбию", false],
    ["болпию", false],
    ["борбию", false],
    ["боржию", false],
    ["борпию", false],
    ["борсию", false],
    ["бортию", false],
    ["бұқию", false],
    ["бұқшию", false],
    ["бұлтию", false],
    ["бұртию", false],
    ["былқию", false],
    ["былпию", false],
    ["былшию", false],
    ["бырбию", false],
    ["быржию", false],
    ["бырсию", false],
    ["быртию", false],
    ["быттию", false],
    ["далдию", false],
    ["дарбию", false],
    ["дардию", false],
    ["дорбию", false],
    ["дырдию", false],
    ["жалпию", false],
    ["жампию", false],
    ["жарбию", false],
    ["жию", false],
    ["жылмию", false],
    ["жылтию", false],
    ["жымию", false],
    ["жымпию", false],
    ["жымсию", false],
    ["жыртию", false],
    ["қаздию", false],
    ["қайқию", false],
    ["қаймию", false],
    ["қақию", false],
    ["қақшию", false],
    ["қалбию", false],
    ["қалқию", false],
    ["қалтию", false],
    ["қалшию", false],
    ["қампию", false],
    ["қаңқию", false],
    ["қаудию", false],
    ["қаужию", false],
    ["қауқию", false],
    ["қаупию", false],
    ["қиқию", false],
    ["қитию", false],
    ["қию", false],
    ["қоқию", false],
    ["қомпию", false],
    ["қонжию", false],
    ["қоңқию", false],
    ["қорбию", false],
    ["қоржию", false],
    ["қушию", false],
    ["құдию", false],
    ["құнжию", false],
    ["құнтию", false],
    ["құржию", false],
    ["қыдию", false],
    ["қылжию", false],
    ["қылмию", false],
    ["қылтию", false],
    ["қыржию", false],
    ["қыртию", false],
    ["лықию", false],
    ["маңқию", false],
    ["миқию", false],
    ["монтию", false],
    ["мықию", false],
    ["мықшию", false],
    ["мыржию", false],
    ["оқшию", false],
    ["сақию", false],
    ["сақсию", false],
    ["саңқию", false],
    ["сапсию", false],
    ["сидию", false],
    ["сойдию", false],
    ["соқию", false],
    ["солпию", false],
    ["сомпию", false],
    ["сопию", false],
    ["состию", false],
    ["сұстию", false],
    ["сықию", false],
    ["сықсию", false],
    ["сылқию", false],
    ["сымпию", false],
    ["сыптию", false],
    ["сырию", false],
    ["тайқию", false],
    ["тайпию", false],
    ["талпию", false],
    ["талтию", false],
    ["таңқию", false],
    ["тарбию", false],
    ["тарпию", false],
    ["тойтию", false],
    ["томпию", false],
    ["тоңқию", false],
    ["торсию", false],
    ["тортию", false],
    ["тостию", false],
    ["тотию", false],
    ["тұғжию", false],
    ["тұқию", false],
    ["тұқшию", false],
    ["тұштию", false],
    ["тылтию", false],
    ["тымпию", false],
    ["тыңқию", false],
    ["тырбию", false],
    ["тыржию", false],
    ["тырию", false],
    ["тырқию", false],
    ["тырсию", false],
    ["тыртию", false],
    ["шақшию", false],
    ["шалжию", false],
    ["шалқию", false],
    ["шанжию", false],
    ["шаңқию", false],
    ["шартию", false],
    ["шойқию", false],
    ["шоқию", false],
    ["шоқшию", false],
    ["шолжию", false],
    ["шолтию", false],
    ["шоңқию", false],
    ["шұқию", false],
    ["шұқшию", false],
    ["шұнтию", false],
    ["шықию", false],
    ["шылқию", false],
    ["ыздию", false],
    ["ыңқию", false],
    ["ыржию", false],
    ["ырсию", false],
    // nouns
    ["ми", false],
]);
const VERB_PERS_AFFIXES1 = {
    First: {
        Singular: ["мын", "мін"],
        Plural: ["мыз", "міз"],
    },
    Second: {
        Singular: ["сың", "сің"],
        Plural: ["сыңдар", "сіңдер"],
    },
    SecondPolite: {
        Singular: ["сыз", "сіз"],
        Plural: ["сыздар", "сіздер"],
    },
    Third: {
        Singular: ["ды", "ді"],
        Plural: ["ды", "ді"]
    }
};
const VERB_PERS_AFFIXES2 = {
    First: {
        Singular: ["м", "м"],
        Plural: ["қ", "к"],
    },
    Second: {
        Singular: ["ң", "ң"],
        Plural: ["ңдар", "ңдер"],
    },
    SecondPolite: {
        Singular: ["ңыз", "ңіз"],
        Plural: ["ңыздар", "ңіздер"],
    },
    Third: {
        Singular: ["", ""],
        Plural: ["", ""]
    }
};
const VERB_WANT_PERS_AFFIXES = {
    First: {
        Singular: ["м", "м"],
        Plural: ["мыз", "міз"],
    },
    Second: {
        Singular: ["ң", "ң"],
        Plural: ["ларың", "лерің"],
    },
    SecondPolite: {
        Singular: ["ңыз", "ңіз"],
        Plural: ["ларыңыз", "леріңіз"],
    },
    Third: {
        Singular: ["сы", "сі"],
        Plural: ["лары", "лері"]
    }
};
const FIRST_PERS_AFFIXES1 = {
    Singular: {
        PersAffix1DefaultGroup: ["мын", "мін"],
        PersAffix1GzGroup: ["бын", "бін"],
        PersAffix1MnGroup: ["мын", "мін"],
        PersAffixUnvoicedGroup: ["пын", "пін"],
    },
    Plural: {
        PersAffix1DefaultGroup: ["мыз", "міз"],
        PersAffix1GzGroup: ["быз", "біз"],
        PersAffix1MnGroup: ["быз", "біз"],
        PersAffixUnvoicedGroup: ["пыз", "піз"]
    },
};
const SECOND_PERS_AFFIXES1 = {
    Singular: ["сың", "сің"],
    Plural: ["сыңдар", "сіңдер"],
};
const SECOND_POLITE_PERS_AFFIXES1 = {
    Singular: ["сыз", "сіз"],
    Plural: ["сыздар", "сіздер"],
};
const THIRD_PERS_AFFIXES3 = {
    Singular: ["ты", "ті"],
    Plural: ["ты", "ті"],
};
const IMPERATIVE_AFFIXES = {
    First: {
        Singular: ["йын", "йін"],
        Plural: ["йық", "йік"],
    },
    Second: {
        Singular: ["", ""],
        Plural: ["ңдар", "ңдер"],
    },
    SecondPolite: {
        Singular: ["ңыз", "ңіз"],
        Plural: ["ңыздар", "ңіздер"],
    },
    Third: {
        Singular: ["сын", "сін"],
        Plural: ["сын", "сін"],
    }
};
const AE = ["а", "е"];
const YI = ["ы", "і"];
const MAME = ["ма", "ме"];
const BABE = ["ба", "бе"];
const PAPE = ["па", "пе"];
/* These are used in negative forms of colloqiual present continuous */
const CLQ_MAME = ["ма", "ми"];
const CLQ_BABE = ["ба", "би"];
const CLQ_PAPE = ["па", "пи"];
const MAKMEK = ["мақ", "мек"];
const BAKBEK = ["бақ", "бек"];
const PAKPEK = ["пақ", "пек"];
const GANGEN = ["ған", "ген"];
const KANKEN = ["қан", "кен"];
const GYGI = ["ғы", "гі"];
const KYKI = ["қы", "кі"];
const DYDI = ["ды", "ді"];
const TYTI = ["ты", "ті"];
const YPIP = ["ып", "іп"];
const AFFIX_ATYR = ["атыр", "ятыр"];
const AFFIX_AT = ["ат", "ят"];
const SASE = ["са", "се"];
const PRONOUN_BY_PERSON_NUMBER = {
    First: {
        Singular: "мен",
        Plural: "біз"
    },
    Second: {
        Singular: "сен",
        Plural: "сендер"
    },
    SecondPolite: {
        Singular: "Сіз",
        Plural: "Сіздер"
    },
    Third: {
        Singular: "ол",
        Plural: "олар"
    }
};
const POSSESSIVE_BY_PERSON_NUMBER = {
    First: {
        Singular: "менің",
        Plural: "біздің"
    },
    Second: {
        Singular: "сенің",
        Plural: "сендердің"
    },
    SecondPolite: {
        Singular: "Сіздің",
        Plural: "Сіздердің"
    },
    Third: {
        Singular: "оның",
        Plural: "олардың"
    }
};
/* we should add і/ы to the base for the following: */
const VERB_PRESENT_TRANSITIVE_EXCEPTIONS1_SET = new Set([
    "абыржу",
    "ағайынсу",
    "адалсу",
    "адамсу",
    "айну",
    "ақылгөйсу",
    "ақылсу",
    "ақынсу",
    "алжу",
    "аңду",
    "аңқаусу",
    "аңқу",
    "апшу",
    "арзу",
    "ару",
    "аршу",
    "астамсу",
    "атқу",
    "аунақшу",
    "ауытқу",
    "аярсу",
    "аяқсу",
    "әзілдегенсу",
    "әкімсу",
    "әсемсу",
    "әспенсу",
    "балқу",
    "балуансу",
    "батпансу",
    "батырсу",
    "батырымсу",
    "баулу",
    "баяусу",
    "бәлсу",
    "бәсеңсу",
    "бейкүнәмсу",
    "бейқамсу",
    "беку",
    "берегенсу",
    "берку",
    "болғансу",
    "боржу",
    "борсу",
    "босаңсу",
    "бөлексу",
    "бөтенсу",
    "буазу",
    "бұлқу",
    "бұлықсу",
    "быжу",
    "бықсу",
    "бықу",
    "бықырсу",
    "былқу",
    "быршу",
    "білгенсу",
    "білгірсу",
    "білгішсу",
    "білдіргенсу",
    "даму",
    "данагөйсу",
    "данасу",
    "дандайсу",
    "данышпансу",
    "даңғойсу",
    "дардайсу",
    "дарқансу",
    "дару",
    "дәнсу",
    "дәусу",
    "дегду",
    "дөңбекшу",
    "дөрекпу",
    "дүмпу",
    "дүңку",
    "ділмарсу",
    "діндарсу",
    "елегенсу",
    "елту",
    "емексу",
    "еркексу",
    "еркесу",
    "еркінсу",
    "ерсу",
    "есту",
    "есіркегенсу",
    "жағымсу",
    "жадыгөйсу",
    "жайбарақатсу",
    "жайдақсу",
    "жақынсу",
    "жалғызсу",
    "жалқаусу",
    "жалқу",
    "жаншу",
    "жасу",
    "жаталақшу",
    "жеку",
    "желпу",
    "жеңілгенсу",
    "жеру",
    "жиду",
    "жомартсу",
    "жору",
    "жосу",
    "жөңку",
    "жуасу",
    "жұлқу",
    "жүйтку",
    "жүнжу",
    "жыбыршу",
    "жылжу",
    "жылу",
    "жылымсу",
    "жылымшу",
    "жібу",
    "жігітсу",
    "жіпсу",
    "зеку",
    "зеңгу",
    "зырғу",
    "кеберсу",
    "кебірсу",
    "кебіртексу",
    "кедейсу",
    "кему",
    "кеңу",
    "кепсу",
    "кербезсу",
    "кергу",
    "кереметсу",
    "керсу",
    "көбеңсу",
    "көгілжу",
    "көлгірсу",
    "көлку",
    "көнсу",
    "көншу",
    "көңірсу",
    "көпсу",
    "көпіршу",
    "көсемсу",
    "күлімсу",
    "күмілжу",
    "күнсу",
    "күпсу",
    "күпу",
    "кілку",
    "кінәзсу",
    "кісімсу",
    "қабаржу",
    "қағып-сілку",
    "қағылжу",
    "қажу",
    "қаймақшу",
    "қақсу",
    "қақшу",
    "қалғу",
    "қалқу",
    "қамқорсу",
    "қамту",
    "қаңғу",
    "қаңсу",
    "қарбу",
    "қарғу",
    "қарпу",
    "қару",
    "қасаңсу",
    "қасу",
    "қобалжу",
    "қожайынсу",
    "қоқсу",
    "қоқу",
    "қоқырсу",
    "қоңылтақсу",
    "қору",
    "құбылжу",
    "құдайсу",
    "құйқылжу",
    "құлазу",
    "құрғақсу",
    "қылғу",
    "қылпу",
    "қылымсу",
    "қымқу",
    "қымту",
    "қыңсу",
    "қырпу",
    "қыршу",
    "қышу",
    "ләйлу",
    "леку",
    "лоблу",
    "лоқсу",
    "лықсу",
    "лықу",
    "лыпу",
    "малту",
    "малшу",
    "манду",
    "маңғазсу",
    "марғаусу",
    "мардамсу",
    "мәңгу",
    "менменсу",
    "меңіреусу",
    "мойынсу",
    "момақансу",
    "мұжу",
    "мүжу",
    "мүләйімсу",
    "мүлгу",
    "мүңку",
    "мүсәпірсу",
    "мығымсу",
    "мыжу",
    "мызғу",
    "мылқаусу",
    "мырзасу",
    "мытқу",
    "мыту",
    "міндетсу",
    "налу",
    "нұқу",
    "обалсу",
    "ойнақшу",
    "оқу",
    "орғу",
    "ортқу",
    "оршу",
    "өгейсу",
    "өзімсу",
    "өксу",
    "өкімсу",
    "өрбу",
    "өрекпу",
    "өрекшу",
    "өршу",
    "өсту",
    "өсіп-өрбу",
    "пақырсу",
    "палуансу",
    "паңсу",
    "пысықсу",
    "ренжу",
    "салақсу",
    "салғансу",
    "салғыртсу",
    "салқамсу",
    "салқынсу",
    "самарқаусу",
    "самсу",
    "саңғу",
    "сапсу",
    "сараңсу",
    "сарқу",
    "сарсу",
    "сару",
    "саябырсу",
    "саяқсу",
    "сәнсу",
    "сәуегейсу",
    "сенгенсу",
    "сепсу",
    "сергексу",
    "сергу",
    "серпу",
    "серімсу",
    "сету",
    "сирексу",
    "сорғу",
    "сусу",
    "суу",
    "сүңгу",
    "сылту",
    "сылу",
    "сыңсу",
    "сыпайысу",
    "сырғақсу",
    "сырғу",
    "сыру",
    "сілку",
    "тайқақсу",
    "тайқу",
    "талмаусу",
    "талықсу",
    "тамылжу",
    "танту",
    "тарпу",
    "тартқансу",
    "тасу",
    "тәкаппарсу",
    "тәлімсу",
    "тәңірсу",
    "тәуелжу",
    "тәуірсу",
    "телу",
    "тепшу",
    "терлеп-тепшу",
    "тершу",
    "тетку",
    "тобарсу",
    "тоқмейілсу",
    "тоқу",
    "толқу",
    "толықсу",
    "тоңазу",
    "тору",
    "төменсу",
    "тұшу",
    "түйткілжу",
    "түйткілсу",
    "түлежу",
    "тықыршу",
    "тыншу",
    "тыпыршу",
    "тілмарсу",
    "уылжу",
    "ұйтқу",
    "ұлу",
    "ұлықсу",
    "ұңғу",
    "үлкенсу",
    "үңгу",
    "үстемсу",
    "үсу",
    "шалқу",
    "шанду",
    "шаншу",
    "шапшу",
    "шарпу",
    "шеку",
    "шешенсу",
    "шоқу",
    "шоршу",
    "шошу",
    "шөжу",
    "шөку",
    "шөпшу",
    "шұқу",
    "шұлғу",
    "шүйгу",
    "шүленсу",
    "шыжу",
    "шылқу",
    "шымшу",
    "шыпшу",
    "шырпу",
    "шіру",
    "ыбылжу",
    "ыбырсу",
    "ызғу",
    "ыңырсу",
    "ырғу",
    "ыршу",
    "ытқу",
    "ілбу",
    "іру",
]);
/* verb to [regular meanings, irregular meanings] */
const OPT_EXCEPT_VERB_MEANINGS = new Map([
    ["ашу", [["открывать", "выявлять"], ["киснуть", "сквашиваться"]]],
    ["еру", [["следовать", "внимать"], ["таять", "растворяться"]]],
    ["жану", [["гореть", "пылать"], ["точить", "править", "оттачивать"]]],
    ["жару", [["колоть", "разрывать"], ["быть обеспеченным"]]],
    ["жуу", [["мыть", "обмывать"], ["быть близким"]]],
    ["ию", [["гнуть", "сгибать"], ["спускать молоко", "раздобриться"]]],
    ["қабу", [["хватать", "ловить"], ["стегать", "простёгивать"]]],
    ["құру", [["строить", "устанавливать"], ["вымирать", "пропадать"]]],
    ["пысу", [["пугаться", "страшиться"], ["крепнуть", "скручиваться"]]],
    ["сасу", [["суетиться", "теряться"], ["вонять", "протухать"]]],
    ["тану", [["отказываться", "отрекаться"], ["узнавать", "знакомиться"]]],
    ["тату", [["отведывать", "есть", "испытывать"], ["приобретать вкус", "заслуживать"]]],
    ["ысу", [["тереть", "натирать"], ["нагреваться", "теплеть"]]],
]);
const VERB_PRESENT_TRANSITIVE_EXCEPTIONS_BASE_SUFFIX = ["ы", "і"];
const VERB_PRESENT_TRANSITIVE_EXCEPTIONS2_SET = new Set([
    "баю",
    "кею",
    "қаю",
    "мою",
    "ұю",
]);
const VERB_PRESENT_CONT_BASE_MAP = new Map([
    ["тұру", "тұр"],
    ["жүру", "жүр"],
    ["отыру", "отыр"],
    ["жату", "жатыр"],
]);
const VERB_PRESENT_CONT_EXCEPTION_A_SET = new Set([
    "бару",
    "апару",
]);
const VERB_PRESENT_CONT_EXCEPTION_E_SET = new Set([
    "келу",
    "әкелу",
]);
const VERB_PRESENT_CONT_EXCEPTION_AE_AUX_ENABLED = "жату";
/* TODO are there more of them? */
const VERB_PRESENT_CONT_EXCEPTION_U_SET = new Set([
    "жабу",
    "қабу",
    "кебу",
    "себу",
    "тебу",
    "табу",
    "шабу",
]);
const VERB_EXCEPTION_ADD_VOWEL_MAP = new Map([
    ["қорқу", "қорық"],
    ["қырқу", "қырық"],
    ["ірку", "ірік"],
    ["бүрку", "бүрік"],
]);
const VERB_LAST_NEGATIVE_CONVERSION = new Map([
    ["б", "п"],
    ["г", "к"],
    ["ғ", "қ"],
]);
/* generic string manipulations that are agnostic to language/grammar details */
function getLastItem(s) {
    return s[s.length - 1];
}
function getLastItemLowered(s) {
    return getLastItem(s).toLowerCase();
}
function checkCharPresence(c, target) {
    for (let i = 0; i < target.length; i++) {
        if (target[i] == c) {
            return true;
        }
    }
    return false;
}
function checkStringInList(s, targets) {
    for (let i = 0; i < targets.length; i++) {
        if (targets[i] == s) {
            return true;
        }
    }
    return false;
}
function chopLast(s, k) {
    const n = s.length;
    if (n > k) {
        return s.slice(0, n - k);
    }
    return "";
}
function replaceLast(s, replacement) {
    const n = s.length;
    if (n == 0) {
        return replacement;
    }
    return `${s.slice(0, n - 1)}${replacement}`;
}
function replaceFirst(s, replacement) {
    const n = s.length;
    if (n == 0) {
        return replacement;
    }
    return `${replacement}${s.slice(1, n)}`;
}
function getLastWord(s) {
    let space = s.lastIndexOf(" ");
    if (space == -1) {
        return s;
    }
    return s.slice(space + 1);
}
var GrammarPerson;
(function (GrammarPerson) {
    GrammarPerson["First"] = "First";
    GrammarPerson["Second"] = "Second";
    GrammarPerson["SecondPolite"] = "SecondPolite";
    GrammarPerson["Third"] = "Third";
})(GrammarPerson || (GrammarPerson = {}));
var GrammarNumber;
(function (GrammarNumber) {
    GrammarNumber["Singular"] = "Singular";
    GrammarNumber["Plural"] = "Plural";
})(GrammarNumber || (GrammarNumber = {}));
var SentenceType;
(function (SentenceType) {
    SentenceType["Statement"] = "Statement";
    SentenceType["Negative"] = "Negative";
    SentenceType["Question"] = "Question";
})(SentenceType || (SentenceType = {}));
var PersAffix1LetterGroup;
(function (PersAffix1LetterGroup) {
    PersAffix1LetterGroup["PersAffix1DefaultGroup"] = "PersAffix1DefaultGroup";
    PersAffix1LetterGroup["PersAffix1GzGroup"] = "PersAffix1GzGroup";
    PersAffix1LetterGroup["PersAffix1MnGroup"] = "PersAffix1MnGroup";
    PersAffix1LetterGroup["PersAffixUnvoicedGroup"] = "PersAffixUnvoicedGroup";
})(PersAffix1LetterGroup || (PersAffix1LetterGroup = {}));
/* shak = tense. Communicates time of the action. */
var VerbShak;
(function (VerbShak) {
    VerbShak["PresentTransitive"] = "PresentTransitive";
    VerbShak["PresentContinuous"] = "PresentContinuous";
})(VerbShak || (VerbShak = {}));
const GRAMMAR_PERSONS = [GrammarPerson.First, GrammarPerson.Second, GrammarPerson.SecondPolite, GrammarPerson.Third];
const GRAMMAR_NUMBERS = [GrammarNumber.Singular, GrammarNumber.Plural];
const GRAMMAR_SENTENCE_TYPES = [SentenceType.Statement, SentenceType.Negative, SentenceType.Question];
function validateVerb(verbDictForm) {
    if (verbDictForm.length < 2) {
        return false;
    }
    if (verbDictForm.length > 100) {
        return false;
    }
    if (verbDictForm.toLowerCase() != verbDictForm) {
        return false;
    }
    let last = getLastItem(verbDictForm);
    return last == "у" || last == "ю";
}
function isVerbException(verbDictForm) {
    const lastWord = getLastWord(verbDictForm);
    return VERB_PRESENT_TRANSITIVE_EXCEPTIONS1_SET.has(lastWord);
}
function getOptExceptVerbMeanings(verbDictForm) {
    const lastWord = getLastWord(verbDictForm);
    return OPT_EXCEPT_VERB_MEANINGS.get(lastWord);
}
function isVerbException2(verbDictForm) {
    return VERB_PRESENT_TRANSITIVE_EXCEPTIONS2_SET.has(verbDictForm);
}
class PresentContinuousContext {
    constructor(verbDictFormBase) {
        this.verbBase = verbDictFormBase;
    }
}
function validPresentContAuxVerb(verbDictForm) {
    return VERB_PRESENT_CONT_BASE_MAP.has(verbDictForm);
}
function validPresentContPair(verb, auxVerb) {
    if (!validPresentContAuxVerb(auxVerb)) {
        return false;
    }
    const aeException = VERB_PRESENT_CONT_EXCEPTION_A_SET.has(verb) || VERB_PRESENT_CONT_EXCEPTION_E_SET.has(verb);
    if (aeException && auxVerb != VERB_PRESENT_CONT_EXCEPTION_AE_AUX_ENABLED) {
        return false;
    }
    return true;
}
const HARD_OFFSET = 0;
const SOFT_OFFSET = 1;
function createPresentContinuousContext(verbDictForm) {
    let verbDictFormBase = VERB_PRESENT_CONT_BASE_MAP.get(verbDictForm);
    if (verbDictFormBase != null) {
        return new PresentContinuousContext(verbDictFormBase);
    }
    return null;
}
class BaseAndExplanationType {
    constructor(base, last, explanationType) {
        this.base = base;
        this.last = last;
        this.explanationType = explanationType;
    }
}
class BaseAndLast {
    constructor(base, last) {
        this.base = base;
        this.last = last;
    }
}
class VerbBuilder {
    constructor(verbDictForm, forceExceptional = false) {
        if (!validateVerb(verbDictForm)) {
            throw new Error("verb dictionary form must end with -у/-ю");
        }
        this.verbDictForm = verbDictForm;
        this.verbBase = chopLast(verbDictForm, 1);
        this.baseExplanation = PART_EXPLANATION_TYPE.VerbBaseStripU;
        this.forceExceptional = forceExceptional;
        this.regularVerbBase = this.verbBase;
        this.needsYaSuffix = false;
        this.soft = wordIsSoft(this.verbDictForm);
        this.softOffset = this.soft ? SOFT_OFFSET : HARD_OFFSET;
        /* exceptions */
        if (isVerbException(verbDictForm) || (getOptExceptVerbMeanings(verbDictForm) != null && forceExceptional)) {
            this.verbBase = this.verbBase + VERB_PRESENT_TRANSITIVE_EXCEPTIONS_BASE_SUFFIX[this.softOffset];
            this.baseExplanation = PART_EXPLANATION_TYPE.VerbBaseGainedY;
        }
        else if (isVerbException2(verbDictForm)) {
            this.verbBase = this.verbBase + "й" + VERB_PRESENT_TRANSITIVE_EXCEPTIONS_BASE_SUFFIX[this.softOffset];
            this.baseExplanation = PART_EXPLANATION_TYPE.VerbBaseGainedIShortY;
        }
        else if (verbDictForm.endsWith("ю")) {
            if (verbDictForm.endsWith("ию")) {
                if (!this.soft) {
                    this.needsYaSuffix = true;
                }
                else {
                    // nothing special here
                }
            }
            else {
                this.verbBase = this.verbBase + "й";
                this.baseExplanation = PART_EXPLANATION_TYPE.VerbBaseGainedIShort;
            }
        }
        this.baseLast = getLastItem(this.verbBase);
        this.contContext = createPresentContinuousContext(verbDictForm);
        this.wantAuxBuilder = null;
        this.canAuxBuilder = null;
        this.defaultContinuousBuilder = null;
    }
    getPersAffix1ExceptThirdPerson(person, number, softOffset) {
        if (person == "Third") {
            return "";
        }
        return VERB_PERS_AFFIXES1[person][number][softOffset];
    }
    /* used only for Statement/Question sentence types */
    presentTransitiveSuffix() {
        if (this.needsYaSuffix) {
            return "я";
        }
        if (genuineVowel(this.baseLast)) {
            return "й";
        }
        if (this.soft) {
            return "е";
        }
        return "а";
    }
    presentColloqShortSuffix(grammarPerson) {
        if (grammarPerson == GrammarPerson.First || grammarPerson == GrammarPerson.Third) {
            return AFFIX_ATYR[this.softOffset];
        }
        return AFFIX_AT[this.softOffset];
    }
    presentColloqLongSuffix(grammarPerson) {
        if (grammarPerson == GrammarPerson.First || grammarPerson == GrammarPerson.Third) {
            if (genuineVowel(this.baseLast)) {
                return "ватыр";
            }
            const vowel = YI[this.softOffset];
            return `${vowel}ватыр`;
        }
        if (genuineVowel(this.baseLast)) {
            return "ват";
        }
        const vowel = YI[this.softOffset];
        return `${vowel}ват`;
    }
    presentColloqSuffix(grammarPerson) {
        if (VERB_PRESENT_CONT_EXCEPTION_E_SET.has(this.verbDictForm) || VERB_PRESENT_CONT_EXCEPTION_A_SET.has(this.verbDictForm)) {
            return this.presentColloqShortSuffix(grammarPerson);
        }
        else {
            return this.presentColloqLongSuffix(grammarPerson);
        }
    }
    presentColloqNegSuffix(grammarPerson) {
        if (grammarPerson == GrammarPerson.First || grammarPerson == GrammarPerson.Third) {
            return "ятыр";
        }
        return "ят";
    }
    possibleFutureSuffix() {
        if (genuineVowel(this.baseLast)) {
            return "р";
        }
        if (this.soft) {
            return "ер";
        }
        return "ар";
    }
    pastTransitiveSuffix(baseLast) {
        if (this.needsYaSuffix) {
            return "ятын";
        }
        if (genuineVowel(baseLast)) {
            if (this.soft) {
                return "йтін";
            }
            return "йтын";
        }
        if (this.soft) {
            return "етін";
        }
        return "атын";
    }
    getNegativeBaseOf(base) {
        let baseLast = getLastItem(base);
        let particle = getQuestionParticle(baseLast, this.softOffset);
        return `${base}${particle}`;
    }
    getNegativeBase() {
        return this.getNegativeBaseOf(this.verbBase);
    }
    getQuestionForm(phrase) {
        let particle = getQuestionParticle(getLastItem(phrase), this.softOffset);
        return `${phrase} ${particle}?`;
    }
    buildQuestionFormGeneric(builder, soft, expl) {
        let last = builder.getLastItem();
        let particle = getQuestionParticle(last, soft ? SOFT_OFFSET : HARD_OFFSET);
        builder.space();
        if (expl) {
            builder.questionParticleWithExplanation(particle, new PartExplanation(PART_EXPLANATION_TYPE.QuestionParticleSeparate, soft));
        }
        else {
            builder.questionParticle(particle);
        }
        return builder.punctuation("?");
    }
    buildQuestionForm(builder) {
        return this.buildQuestionFormGeneric(builder, this.soft, false);
    }
    buildQuestionFormExpl(builder) {
        return this.buildQuestionFormGeneric(builder, this.soft, true);
    }
    buildUnclassified(phrase) {
        return new PhrasalBuilder()
            .unclassified(phrase)
            .build();
    }
    genericBaseModifier(nc, yp) {
        if (nc) { /* next is consonant */
            if (yp) {
                throw new Error(`invalid arguments: ${nc}, ${yp}`);
            }
            /* қорқу -> қорық.. */
            let addVowel = VERB_EXCEPTION_ADD_VOWEL_MAP.get(this.verbDictForm);
            if (addVowel) {
                let last = getLastItem(addVowel);
                return new BaseAndExplanationType(addVowel, last, PART_EXPLANATION_TYPE.VerbBaseGainedYInsidePriorCons);
            }
            let last = getLastItem(this.verbBase);
            let replacement = VERB_LAST_NEGATIVE_CONVERSION.get(last);
            if (replacement != null) {
                return new BaseAndExplanationType(replaceLast(this.verbBase, replacement), replacement, PART_EXPLANATION_TYPE.VerbBaseReplaceLastCons);
            }
        }
        else if (yp) { /* next is -ып */
            /* жабу -> жау, except қабу can become қау or қабы (if forceExceptional) */
            if (VERB_PRESENT_CONT_EXCEPTION_U_SET.has(this.verbDictForm) && !this.forceExceptional) {
                let replacement = "у";
                return new BaseAndExplanationType(replaceLast(this.regularVerbBase, replacement), replacement, PART_EXPLANATION_TYPE.VerbBaseReplaceB2U);
            }
        }
        return new BaseAndExplanationType(this.verbBase, this.baseLast, this.baseExplanation);
    }
    // TODO replace with genericBaseModifier()
    fixUpSpecialBaseForConsonant() {
        let specialBase = VERB_EXCEPTION_ADD_VOWEL_MAP.get(this.verbDictForm);
        if (specialBase != null) {
            return specialBase;
        }
        return this.verbBase;
    }
    // TODO do not force exceptional, use fixUpSpecialBaseForConsonant()
    fixUpSpecialBaseForConsonantAndForceExceptional() {
        let specialBase = VERB_EXCEPTION_ADD_VOWEL_MAP.get(this.verbDictForm);
        if (specialBase != null) {
            return specialBase;
        }
        let meanings = getOptExceptVerbMeanings(this.verbDictForm);
        if (meanings != null) {
            return this.verbBase + VERB_PRESENT_TRANSITIVE_EXCEPTIONS_BASE_SUFFIX[this.softOffset];
        }
        return this.verbBase;
    }
    // TODO replace with genericBaseModifier()
    fixUpSpecialBaseForceExceptional() {
        let meanings = getOptExceptVerbMeanings(this.verbDictForm);
        if (meanings != null) {
            return this.verbBase + VERB_PRESENT_TRANSITIVE_EXCEPTIONS_BASE_SUFFIX[this.softOffset];
        }
        return this.verbBase;
    }
    mergeBaseWithVowelAffix(origBase, origAffix, expl) {
        var base = origBase;
        var affix = origAffix;
        let baseExplanation = new PartExplanation(PART_EXPLANATION_TYPE.VerbBaseStripU, this.soft);
        let affixExplanation = new PartExplanation(PART_EXPLANATION_TYPE.VerbTenseAffixPresentTransitive, this.soft);
        if (base.endsWith("й") && affix.startsWith("а")) {
            base = chopLast(base, 1);
            baseExplanation.explanationType = PART_EXPLANATION_TYPE.VerbBaseLostIShort;
            affix = replaceFirst(affix, "я");
            affixExplanation.explanationType = PART_EXPLANATION_TYPE.VerbTenseAffixPresentTransitiveToYa;
        }
        else if ((base.endsWith("ы") || base.endsWith("і")) && affix.startsWith("й")) {
            base = chopLast(base, 1);
            baseExplanation.explanationType = (this.baseExplanation == PART_EXPLANATION_TYPE.VerbBaseGainedIShortY
                ? PART_EXPLANATION_TYPE.VerbBaseGainIShortLoseY
                : PART_EXPLANATION_TYPE.VerbBaseLostY);
            affix = replaceFirst(affix, "и");
            affixExplanation.explanationType = PART_EXPLANATION_TYPE.VerbTenseAffixPresentTransitiveToYi;
        }
        if (expl) {
            return new PhrasalBuilder()
                .verbBaseWithExplanation(base, baseExplanation)
                .tenseAffixWithExplanation(affix, affixExplanation);
        }
        else {
            return new PhrasalBuilder()
                .verbBase(base)
                .tenseAffix(affix);
        }
    }
    presentTransitiveCommonBuilder() {
        return this.mergeBaseWithVowelAffix(this.verbBase, this.presentTransitiveSuffix(), true);
    }
    fixUpBaseForConsonant(base, last) {
        let replacement = VERB_LAST_NEGATIVE_CONVERSION.get(last);
        if (replacement != null) {
            return new BaseAndLast(replaceLast(base, replacement), replacement);
        }
        return new BaseAndLast(base, last);
    }
    appendPresentTransitivePersAffix(person, number, sentenceType, builder) {
        let persAffix = null;
        let explType = null;
        if (sentenceType != SentenceType.Question || person != GrammarPerson.Third) {
            persAffix = VERB_PERS_AFFIXES1[person][number][this.softOffset];
            explType = PART_EXPLANATION_TYPE.VerbPersonalAffixPresentTransitive;
        }
        else {
            persAffix = "";
            explType = PART_EXPLANATION_TYPE.VerbPersonalAffixPresentTransitiveQuestionSkip;
        }
        let persAffixExplanation = new PartExplanation(explType, this.soft);
        return builder.personalAffixWithExplanation(persAffix, persAffixExplanation);
    }
    /* Ауыспалы осы/келер шақ */
    presentTransitiveForm(person, number, sentenceType) {
        if (sentenceType == "Statement") {
            return this.appendPresentTransitivePersAffix(person, number, sentenceType, this.presentTransitiveCommonBuilder()).build();
        }
        else if (sentenceType == "Negative") {
            let pastBase = this.genericBaseModifier(/* nc */ true, /* yp */ false);
            let baseExplanation = new PartExplanation(pastBase.explanationType, this.soft);
            let particle = getQuestionParticle(pastBase.last, this.softOffset);
            let particleE = new PartExplanation(PART_EXPLANATION_TYPE.VerbNegationPostBase, this.soft);
            let affixE = new PartExplanation(PART_EXPLANATION_TYPE.VerbTenseAffixPresentTransitive, this.soft);
            return this.appendPresentTransitivePersAffix(person, number, sentenceType, new PhrasalBuilder()
                .verbBaseWithExplanation(pastBase.base, baseExplanation)
                .negationWithExplanation(particle, particleE)
                .tenseAffixWithExplanation("й", affixE)).build();
        }
        else if (sentenceType == "Question") {
            return this.buildQuestionFormExpl(this.appendPresentTransitivePersAffix(person, number, sentenceType, this.presentTransitiveCommonBuilder())).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    presentSimpleContinuousCommonBuilder(person, number) {
        if (this.contContext == null) {
            return new PhrasalBuilder();
        }
        let persAffix = this.getPersAffix1ExceptThirdPerson(person, number, this.softOffset);
        return new PhrasalBuilder()
            .verbBase(this.contContext.verbBase)
            .personalAffix(persAffix);
    }
    /* Нақ осы шақ */
    presentSimpleContinuousForm(person, number, sentenceType) {
        if (this.contContext == null) {
            return NOT_SUPPORTED_PHRASAL;
        }
        if (sentenceType == "Statement") {
            return this.presentSimpleContinuousCommonBuilder(person, number)
                .build();
        }
        else if (sentenceType == "Negative") {
            let affix = getGangenKanken(this.baseLast, this.softOffset);
            // parameters of "жоқ", not of the verb base
            let gokLast = 'қ';
            let gokSoftOffset = 0;
            let persAffix = getPersAffix1(person, number, gokLast, gokSoftOffset);
            return new PhrasalBuilder()
                .verbBase(this.verbBase)
                .tenseAffix(affix)
                .space()
                .negation("жоқ")
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == "Question") {
            return this.buildQuestionForm(this.presentSimpleContinuousCommonBuilder(person, number)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    // TODO replace with genericBaseModifier()
    getPresentContinuousBase() {
        if (VERB_PRESENT_CONT_EXCEPTION_U_SET.has(this.verbDictForm) && !this.forceExceptional) {
            return replaceLast(this.verbBase, "у");
        }
        return this.verbBase;
    }
    getPresentContinousAffix() {
        if (VERB_PRESENT_CONT_EXCEPTION_A_SET.has(this.verbDictForm)) {
            return "а";
        }
        if (VERB_PRESENT_CONT_EXCEPTION_E_SET.has(this.verbDictForm)) {
            return "е";
        }
        return getYpip(this.baseLast, this.softOffset);
    }
    presentContinuousForm(person, number, sentenceType, auxBuilder, negateAux = true) {
        if (auxBuilder.contContext == null) {
            return NOT_SUPPORTED_PHRASAL;
        }
        const aeException = VERB_PRESENT_CONT_EXCEPTION_A_SET.has(this.verbDictForm) || VERB_PRESENT_CONT_EXCEPTION_E_SET.has(this.verbDictForm);
        const forbidden = aeException && auxBuilder.verbDictForm != VERB_PRESENT_CONT_EXCEPTION_AE_AUX_ENABLED;
        if (sentenceType != SentenceType.Negative || negateAux) {
            const verbBase = this.getPresentContinuousBase();
            const affix = this.getPresentContinousAffix();
            const auxVerbPhrasal = auxBuilder.presentSimpleContinuousForm(person, number, sentenceType);
            return new PhrasalBuilder()
                .verbBase(verbBase)
                .tenseAffix(affix)
                .space()
                .auxVerb(auxVerbPhrasal)
                .setForbidden(forbidden)
                .build();
        }
        else {
            const verbBase = this.genericBaseModifier(/* nc */ true, /* yp */ false);
            const particle = getQuestionParticle(verbBase.last, this.softOffset);
            const affix = "й";
            const auxVerbPhrasal = auxBuilder.presentSimpleContinuousForm(person, number, SentenceType.Statement);
            return new PhrasalBuilder()
                .verbBase(verbBase.base)
                .negation(particle)
                .tenseAffix(affix)
                .space()
                .auxVerb(auxVerbPhrasal)
                .setForbidden(forbidden)
                .build();
        }
    }
    presentColloquialBuilder(person, number) {
        const verbBase = this.getPresentContinuousBase();
        const affix = this.presentColloqSuffix(person);
        const persAffix = this.getPersAffix1ExceptThirdPerson(person, number, HARD_OFFSET);
        return new PhrasalBuilder()
            .verbBase(verbBase)
            .tenseAffix(affix)
            .personalAffix(persAffix);
    }
    presentColloquialForm(person, number, sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.presentColloquialBuilder(person, number)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            const verbBase = this.genericBaseModifier(/* nc */ true, /* yp */ false);
            const particle = getColloquialQuestionParticle(verbBase.last, this.softOffset);
            const affix = this.presentColloqNegSuffix(person);
            const persAffix = this.getPersAffix1ExceptThirdPerson(person, number, HARD_OFFSET);
            return new PhrasalBuilder()
                .verbBase(verbBase.base)
                .negation(particle)
                .tenseAffix(affix)
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionFormGeneric(this.presentColloquialBuilder(person, number), false, false).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    getDefaultContinuousBuilder() {
        if (this.defaultContinuousBuilder == null) {
            this.defaultContinuousBuilder = new VerbBuilder("жату");
        }
        return this.defaultContinuousBuilder;
    }
    getFormByShak(person, number, sentenceType, shak) {
        if (shak == "PresentTransitive") {
            return this.presentTransitiveForm(person, number, sentenceType);
        }
        else if (shak == "PresentContinuous") {
            return this.presentContinuousForm(person, number, sentenceType, this.getDefaultContinuousBuilder(), /* negateAux */ false);
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    /* Қалау рай */
    getWantAuxBuilder() {
        if (this.wantAuxBuilder == null) {
            this.wantAuxBuilder = new VerbBuilder("келу");
        }
        return this.wantAuxBuilder;
    }
    getWantAuxVerb(sentenceType, shak) {
        if (shak == VerbShak.PresentContinuous && sentenceType != SentenceType.Negative) {
            let contAuxVerb = this.getDefaultContinuousBuilder().presentSimpleContinuousForm(GrammarPerson.Third, GrammarNumber.Singular, sentenceType).raw;
            let res = `келіп ${contAuxVerb}`;
            return this.buildUnclassified(res);
        }
        return this.getWantAuxBuilder().getFormByShak(GrammarPerson.Third, GrammarNumber.Singular, sentenceType, shak);
    }
    wantClause(person, number, sentenceType, shak) {
        let specialBase = this.fixUpSpecialBaseForConsonant();
        let baseAndLast = this.fixUpBaseForConsonant(specialBase, getLastItem(specialBase));
        let affix = getGygiKyki(baseAndLast.last, this.softOffset);
        let persAffix = VERB_WANT_PERS_AFFIXES[person][number][this.softOffset];
        let auxVerbPhrasal = this.getWantAuxVerb(sentenceType, shak);
        return new PhrasalBuilder()
            .verbBase(baseAndLast.base)
            .tenseAffix(affix)
            .personalAffix(persAffix)
            .space()
            .auxVerb(auxVerbPhrasal)
            .build();
    }
    getCanAuxBuilder() {
        if (this.canAuxBuilder == null) {
            this.canAuxBuilder = new VerbBuilder("алу");
        }
        return this.canAuxBuilder;
    }
    canClause(person, number, sentenceType, shak) {
        let auxVerbPhrasal = this.getCanAuxBuilder().getFormByShak(person, number, sentenceType, shak);
        return this.presentTransitiveCommonBuilder()
            .space()
            .auxVerb(auxVerbPhrasal)
            .build();
    }
    pastCommonBuilder() {
        let pastBase = this.fixUpSpecialBaseForConsonant();
        let baseAndLast = this.fixUpBaseForConsonant(pastBase, getLastItem(pastBase));
        let affix = getDydiTyti(baseAndLast.last, this.softOffset);
        return new PhrasalBuilder()
            .verbBase(baseAndLast.base)
            .tenseAffix(affix);
    }
    /* Жедел өткен шақ */
    pastForm(person, number, sentenceType) {
        let persAffix = VERB_PERS_AFFIXES2[person][number][this.softOffset];
        if (sentenceType == SentenceType.Statement) {
            return this.pastCommonBuilder()
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let pastBase = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(pastBase, getLastItem(pastBase));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let affix = DYDI[this.softOffset];
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix)
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.pastCommonBuilder()
                .personalAffix(persAffix)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    possibleFutureCommonBuilder() {
        var base = this.verbBase;
        var affix = this.possibleFutureSuffix();
        if (base.endsWith("й") && affix == "ар") {
            base = chopLast(base, 1);
            affix = "яр";
        }
        return new PhrasalBuilder()
            .verbBase(base)
            .tenseAffix(affix);
    }
    /* Болжалды келер шақ */
    possibleFutureForm(person, number, sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            let builder = this.possibleFutureCommonBuilder();
            let affixLast = builder.getLastItem();
            let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
            return builder
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let specialBase = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(specialBase, getLastItem(specialBase));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let affix = "с";
            let persAffix = getPersAffix1(person, number, affix, this.softOffset);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix)
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            let builder = this.possibleFutureCommonBuilder();
            let affixLast = builder.getLastItem();
            let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
            return this.buildQuestionForm(builder.
                personalAffix(persAffix)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    intentionFutureCommonBuilder(person, number) {
        let specialBase = this.fixUpSpecialBaseForConsonant();
        let baseAndLast = this.fixUpBaseForConsonant(specialBase, getLastItem(specialBase));
        let tenseAffix = getIntentionFutureAffix(baseAndLast.last, this.softOffset);
        let affixLast = getLastItem(tenseAffix);
        let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
        return new PhrasalBuilder()
            .verbBase(baseAndLast.base)
            .tenseAffix(tenseAffix)
            .personalAffix(persAffix);
    }
    /* Мақсатты келер шақ */
    intentionFutureForm(person, number, sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.intentionFutureCommonBuilder(person, number)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let specialBase = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(specialBase, getLastItem(specialBase));
            let tenseAffix = getIntentionFutureAffix(baseAndLast.last, this.softOffset);
            // last sound and softness come from "емес"
            let persAffix = getPersAffix1(person, number, "с", 1);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .tenseAffix(tenseAffix)
                .space()
                .negation("емес")
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.intentionFutureCommonBuilder(person, number)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    remotePastCommonBuilder() {
        let specialBase = this.fixUpSpecialBaseForConsonant();
        let baseAndLast = this.fixUpBaseForConsonant(specialBase, getLastItem(specialBase));
        let affix = getGangenKanken(baseAndLast.last, this.softOffset);
        return new PhrasalBuilder()
            .verbBase(baseAndLast.base)
            .tenseAffix(affix);
    }
    /* Бұрынғы өткен шақ */
    remotePastTense(person, number, sentenceType, negateAux = true) {
        if (sentenceType == SentenceType.Statement) {
            let builder = this.remotePastCommonBuilder();
            let affixLast = builder.getLastItem();
            let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
            return builder
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            if (negateAux) {
                let builder = this.remotePastCommonBuilder();
                // parameters of "жоқ", not of the verb base
                let gokLast = 'қ';
                let gokSoftOffset = 0;
                let persAffix = getPersAffix1(person, number, gokLast, gokSoftOffset);
                return builder
                    .space()
                    .negation("жоқ")
                    .personalAffix(persAffix)
                    .build();
            }
            else {
                const verbBase = this.genericBaseModifier(/* nc */ true, /* yp */ false);
                const particle = getQuestionParticle(verbBase.last, this.softOffset);
                const particleLast = getLastItem(particle);
                const affix = getGangenKanken(particleLast, this.softOffset);
                const affixLast = getLastItem(affix);
                const persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
                return new PhrasalBuilder()
                    .verbBase(verbBase.base)
                    .negation(particle)
                    .tenseAffix(affix)
                    .personalAffix(persAffix)
                    .build();
            }
        }
        else if (sentenceType == SentenceType.Question) {
            let builder = this.remotePastCommonBuilder();
            let affixLast = builder.getLastItem();
            let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
            return this.buildQuestionForm(builder
                .personalAffix(persAffix)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    pastUncertainCommonBuilder() {
        let base = this.genericBaseModifier(/* nc */ false, /* yp */ true).base;
        let baseLast = getLastItem(base);
        let affix = getYpip(baseLast, this.softOffset);
        return new PhrasalBuilder()
            .verbBase(base)
            .tenseAffix(affix);
    }
    /* Күмәнді өткен шақ */
    pastUncertainTense(person, number, sentenceType) {
        let persAffix = getPersAffix3(person, number, this.softOffset);
        if (sentenceType == SentenceType.Statement) {
            return this.pastUncertainCommonBuilder()
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let baseAndLast = this.genericBaseModifier(/* nc */ true, /* yp */ false);
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let particleLast = getLastItem(particle);
            let affix = getYpip(particleLast, this.softOffset);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix)
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.pastUncertainCommonBuilder()
                .personalAffix(persAffix)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    presentParticipleCommonBuilder(expl) {
        return this.mergeBaseWithVowelAffix(this.verbBase, this.pastTransitiveSuffix(this.baseLast), expl);
    }
    pastTransitiveCommonBuilder(person, number) {
        let builder = this.presentParticipleCommonBuilder(true);
        let affixLast = builder.getLastItem();
        let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
        return builder
            .personalAffix(persAffix);
    }
    /* Ауыспалы өткен шақ: -атын */
    pastTransitiveTense(person, number, sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.pastTransitiveCommonBuilder(person, number)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let base = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(base, getLastItem(base));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let particleLast = getLastItem(particle);
            let affix = this.pastTransitiveSuffix(particleLast);
            let affixLast = getLastItem(affix);
            let persAffix = getPersAffix1(person, number, affixLast, this.softOffset);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix)
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.pastTransitiveCommonBuilder(person, number)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    conditionalMoodCommonBuilder() {
        let pastBase = this.fixUpSpecialBaseForConsonant();
        let baseAndLast = this.fixUpBaseForConsonant(pastBase, getLastItem(pastBase));
        let affix = getSase(this.softOffset);
        return new PhrasalBuilder()
            .verbBase(baseAndLast.base)
            .tenseAffix(affix);
    }
    /* Шартты рай: -са */
    conditionalMood(person, number, sentenceType) {
        let persAffix = VERB_PERS_AFFIXES2[person][number][this.softOffset];
        if (sentenceType == SentenceType.Statement) {
            return this.conditionalMoodCommonBuilder()
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let base = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(base, getLastItem(base));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let affix = getSase(this.softOffset);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix)
                .personalAffix(persAffix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.conditionalMoodCommonBuilder()
                .personalAffix(persAffix)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    imperativeMoodCommonBuilder(person, number) {
        let nc = ((person == GrammarPerson.Second && number == GrammarNumber.Singular)
            || person == GrammarPerson.Third);
        let baseAndLast = this.genericBaseModifier(nc, /* yp */ false);
        let affix = getImperativeAffix(person, number, baseAndLast.last, this.softOffset);
        return this.mergeBaseWithVowelAffix(baseAndLast.base, affix, false);
    }
    /* Бұйрық рай */
    imperativeMood(person, number, sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.imperativeMoodCommonBuilder(person, number)
                .build();
        }
        else if (sentenceType == SentenceType.Negative) {
            let base = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(base, getLastItem(base));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let particleLast = getLastItem(particle);
            let affix = getImperativeAffix(person, number, particleLast, this.softOffset);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix)
                .build();
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.imperativeMoodCommonBuilder(person, number)).build();
        }
        return NOT_SUPPORTED_PHRASAL;
    }
    /* Өткен шақ есімше */
    pastParticipleBuilder(sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.remotePastCommonBuilder();
        }
        else if (sentenceType == SentenceType.Negative) {
            let base = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(base, getLastItem(base));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let particleLast = getLastItem(particle);
            let affix = getGangenKanken(particleLast, this.softOffset);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix);
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.remotePastCommonBuilder());
        }
        return null;
    }
    pastParticiple(sentenceType) {
        return finalizeMaybePhrasalBuilder(this.pastParticipleBuilder(sentenceType));
    }
    presentParticipleBuilder(sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.presentParticipleCommonBuilder(false);
        }
        else if (sentenceType == SentenceType.Negative) {
            let base = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(base, getLastItem(base));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let particleLast = getLastItem(particle);
            let affix = this.pastTransitiveSuffix(particleLast);
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix);
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.presentParticipleCommonBuilder(false));
        }
        return null;
    }
    presentParticiple(sentenceType) {
        return finalizeMaybePhrasalBuilder(this.presentParticipleBuilder(sentenceType));
    }
    futureParticipleBuilder(sentenceType) {
        if (sentenceType == SentenceType.Statement) {
            return this.possibleFutureCommonBuilder();
        }
        else if (sentenceType == SentenceType.Negative) {
            let specialBase = this.fixUpSpecialBaseForConsonant();
            let baseAndLast = this.fixUpBaseForConsonant(specialBase, getLastItem(specialBase));
            let particle = getQuestionParticle(baseAndLast.last, this.softOffset);
            let affix = "с";
            return new PhrasalBuilder()
                .verbBase(baseAndLast.base)
                .negation(particle)
                .tenseAffix(affix);
        }
        else if (sentenceType == SentenceType.Question) {
            return this.buildQuestionForm(this.possibleFutureCommonBuilder());
        }
        return null;
    }
    futureParticiple(sentenceType) {
        return finalizeMaybePhrasalBuilder(this.futureParticipleBuilder(sentenceType));
    }
}
/* TODO find a reference, current implementation is just a guess */
function gangenCompatible(c) {
    return vowel(c) || checkCharPresence(c, CONS_GROUP1) || checkCharPresence(c, CONS_GROUP2);
}
function getGangenKanken(c, softOffset) {
    if (gangenCompatible(c)) {
        return GANGEN[softOffset];
    }
    return KANKEN[softOffset];
}
function kykiCompatible(c) {
    return checkCharPresence(c, CONS_GROUP4) || checkCharPresence(c, CONS_GROUP5);
}
function getGygiKyki(c, softOffset) {
    if (kykiCompatible(c)) {
        return KYKI[softOffset];
    }
    return GYGI[softOffset];
}
function tytiCompatible(c) {
    return kykiCompatible(c);
}
function getDydiTyti(c, softOffset) {
    if (tytiCompatible(c)) {
        return TYTI[softOffset];
    }
    return DYDI[softOffset];
}
function getYpip(c, softOffset) {
    if (genuineVowel(c)) {
        return "п";
    }
    return YPIP[softOffset];
}
function getSase(softOffset) {
    return SASE[softOffset];
}
export {
    GRAMMAR_PERSONS,
    GRAMMAR_NUMBERS,
    PHRASAL_PART_TYPE,
    PART_EXPLANATION_TYPE,
    PRONOUN_BY_PERSON_NUMBER,
    POSSESSIVE_BY_PERSON_NUMBER,
    SEPTIKS,
    validateVerb,
    getDeclAltInfo,
    getOptExceptVerbMeanings,
    validPresentContPair,
    NounBuilder,
    VerbBuilder,
}